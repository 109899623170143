import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject, combineLatest, concat, Observable, of, pairwise, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, skip, takeUntil, tap } from 'rxjs/operators';

import { SVG_ICONS_TYPE } from '@constants';
import { TreeDataAutocompleteService } from '@services/shared';
import { CachedProductsAndCategories, FunctionType, InCatalogResponse } from '@typings';
import { isMobileBrowser, notEmpty } from '@utils';

import { BaseComponent } from '../base-component/base.component';
import { HintVariant } from '../control-hint/control-hint.component';
import { DropdownMenuComponent } from '../dropdown-menu/dropdown-menu.component';
import { MenuComponent } from '../menu/menu.component';
import { MenuItem, MenuItemType } from '../menu-item/menu-item.model';

import {
  AutocompleteLoadingState,
  AutocompleteOption,
  AutocompleteSelectAllEvent,
  collapsedLabelType,
  SelectAllSettings,
  UpdateSelected,
} from './autocomplete.model';

export type SearchFnType = (searchText: string) => void;
@Component({
  selector: 'nm-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
  ],
})
export class AutocompleteComponent extends BaseComponent implements OnDestroy, ControlValueAccessor, OnInit, DoCheck {
  #defaultLabelField = 'label';
  menu: MenuComponent;
  readonly loadingType: MenuItemType = 'loading';
  readonly loadingOption: AutocompleteOption = {
    id: this.loadingType,
    type: this.loadingType,
    label: this.loadingType,
  };
  @ViewChild('input', { static: false }) inputElem: ElementRef<HTMLInputElement>;
  @ViewChild('formField', { read: ElementRef, static: false }) fieldElem: ElementRef<HTMLInputElement>;
  @ViewChild('dropdownMenu', { read: DropdownMenuComponent }) dropdownMenu: DropdownMenuComponent;
  @ViewChild('menu') set menuView(content: MenuComponent) {
    this.menu = content;
  }

  @Input() testId: string;
  @Input() placeholder: string = '';
  @Input() label: string;
  @Input() showLabelIcon: boolean = false;
  @Input() isMulti: boolean = false;
  @Input() hint: string;
  @Input() prefix: string;
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() error: boolean;
  @Input() maxLength: number;
  @Input() limitTags: number | undefined;

  @Input() set options(options: Observable<AutocompleteOption[]>) {
    this.options$ = options;
  }
  @Input() set multiSelectTreeUpdateSelected(updateSelected: UpdateSelected | undefined) {
    if (updateSelected !== undefined) {
      this.updateSelected$.next(updateSelected);
    }
  }
  @Input() set allSelected(allSelected: boolean | undefined) {
    if (allSelected !== undefined) {
      this.allSelectedCondition = allSelected;
    }
  }
  @Input() loadingState: AutocompleteLoadingState = of({ reloading: false, canLoadMore: false });
  @Input() disableCreateOnEmpty: boolean = true;
  @Input() createOption: boolean = false;
  @Input() search: boolean = false;
  @Input() searchCharsCount: number = 1;
  @Input() isCustomInput: boolean = false;
  @Input() returnObjects: boolean = false;
  @Input() searchFn: SearchFnType | undefined = undefined;
  @Input() getSelectedOptionFn: (selectedId: string) => AutocompleteOption | null;
  @Input() optionSelectedInModelFn: (selectedId?: string) => boolean = () => false;
  @Input() selectAllSettings: SelectAllSettings;

  @Input() collapseChipsLabel: collapsedLabelType = '';
  @Input() disableModelUpdatesFromMenu: boolean = false;
  @Input() optionLoading: boolean = false;
  @Input() showClear: boolean = true;
  @Input() showOpen: boolean = true;
  @Input() showDeleteChip: boolean = true;
  @Input() autocompleteTreeService: TreeDataAutocompleteService;

  @Output() menuOpen = new EventEmitter<void>();
  @Output() create = new EventEmitter();
  @Output() loadMore = new EventEmitter();
  @Output() selectAllHandler = new EventEmitter<AutocompleteSelectAllEvent>();
  @Output() deselectAll = new EventEmitter();
  @Output() deleteChip = new EventEmitter<string[]>();

  options$: Observable<AutocompleteOption[]> = of([]);
  private destroyed$ = new Subject<void>();
  onTouched: FunctionType = () => '';
  onChange: FunctionType = () => '';
  isFocused: boolean = false;
  isMenuOpened: boolean = false;

  searchText: string = '';
  searchText$: BehaviorSubject<string> = new BehaviorSubject('');
  searchObservable$ = this.searchText$.pipe(skip(1), debounceTime(600), distinctUntilChanged(), shareReplay(), takeUntil(this.destroyed$));

  selectedItems$: Observable<(AutocompleteOption | null)[]>;
  selectedValue$: Observable<AutocompleteOption | null>;
  updateSelected$ = new Subject<UpdateSelected>();

  valueColor: string | undefined;
  valueIcon: SVG_ICONS_TYPE | undefined;
  valueImage: string | SafeUrl | undefined;

  isDenseMode: boolean;

  selectedIdsValue: Set<string> = new Set();

  #selectedIds: BehaviorSubject<Set<string> | undefined> = new BehaviorSubject<Set<string> | undefined>(undefined);
  selectedIds$ = this.#selectedIds.asObservable();

  #initailOptions: AutocompleteOption[] = [];
  #additionalOptions: BehaviorSubject<AutocompleteOption[]> = new BehaviorSubject(this.#initailOptions);
  additionalOptions$ = this.#additionalOptions.asObservable();

  optionsList$: Observable<AutocompleteOption[]>;
  isLoading$: Observable<boolean>;
  menuPanelClass = 'nm-autocomplete-menu';

  menuHeader: MenuItem | null = null;
  allSelectedCondition: boolean | null = null;

  selectedItemsCount = {
    categories: 0,
    products: 0,
  };

  get counterText(): string {
    if (!this.maxLength) return '';
    return `${this.searchText.length}/${this.maxLength}`;
  }

  get labelField(): string {
    return this.#defaultLabelField;
  }

  get hintType(): HintVariant {
    return this.error ? 'error' : 'helper';
  }

  get showSearchInput(): boolean {
    return this.search || this.isCustomInput;
  }

  get needFilter(): boolean {
    if (!!this.searchFn) return false;
    return this.canSearch() && !this.singleValueIsSelected();
  }

  get optionsFilter(): (option: AutocompleteOption) => boolean {
    if (!this.needFilter) return () => true;
    const filterValue = this.searchText.toLowerCase();
    return (option) => {
      if (option.isSpinner) return true;
      return this.displayValue(option).toLowerCase().includes(filterValue);
    };
  }

  get selectedHasPrefix(): boolean {
    return !!this.valueColor || !!this.valueIcon || !!this.valueImage;
  }

  get showCreate(): boolean {
    return this.createOption && !!this.searchText && !this.isCustomInput;
  }

  get showClearBtn(): boolean {
    return this.showClear && !this.canSelectAll() && (!!this.searchText || !!this.selectedIdsValue.size) && !this.disabled;
  }

  get limitChipsNumber(): number | undefined {
    return this.isMenuOpened ? undefined : this.limitTags;
  }

  get showAllChip(): boolean {
    return !!this.isMulti && !!this.allSelectedCondition;
  }

  @HostListener('window:resize') onResize(): void {
    if (!isMobileBrowser()) {
      this.closeMenu();
    }
  }

  @HostListener('document:click', ['$event'])
  public onClickOutside(event: { target: HTMLElement }) {
    const menuElem = this.getMenuElem();
    if (!this.fieldElem.nativeElement.contains(event.target) && !menuElem?.contains(event.target)) {
      if (!event.target.matches('.cdk-overlay-backdrop')) {
        this.isFocused = false;
      }
      this.closeMenu();
    }
  }

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngDoCheck() {
    this.initMenuWidth();
  }

  ngOnInit(): void {
    this.optionsList$ = concat(
      of([this.loadingOption]),
      combineLatest([this.additionalOptions$, this.options$, this.loadingState]).pipe(
        map(([addOptions, options, loadingState]) => {
          if (loadingState.reloading && !loadingState.canLoadMore) {
            return [this.loadingOption];
          }

          const optionsIds = options.map((opt) => opt.id);
          const optionsToAdd = addOptions.filter((opt) => !optionsIds.includes(opt.id) && !this.optionSelectedInModelFn(opt.id));
          let newOptions;
          if (this.isMulti) {
            if (this.searchText !== '') {
              newOptions = options;
            } else {
              newOptions = options.concat(optionsToAdd);
            }
          } else {
            newOptions = optionsToAdd.concat(options);
          }
          return newOptions;
        }),
      ),
    ).pipe(takeUntil(this.destroyed$));

    if (!!this.searchFn) {
      this.searchObservable$.subscribe((searchText) => {
        if (this.searchFn) {
          this.searchFn(searchText);
        }
      });
    }

    this.initSelected();
    this.updateSelected$.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      let previousValue = this.selectedIdsValue;
      if (data['selected']) {
        const updatedValue = new Set([...data['itemsSet'], ...previousValue]);

        this.updateSelectedIds(updatedValue);
      } else {
        const updatedValue = new Set(
          [...previousValue].filter((item) => {
            return !data['itemsSet'].has(item);
          }),
        );
        this.updateSelectedIds(updatedValue);
      }
    });
    this.selectedIds$.pipe(takeUntil(this.destroyed$)).subscribe((selectedIds) => {
      if (selectedIds) {
        this.selectedIdsValue = selectedIds;
      }
    });
    this.selectedItems$.pipe(takeUntil(this.destroyed$)).subscribe((res) => {
      let itemsCount = {
        categories: 0,
        products: 0,
      };
      const selectedItems = res as AutocompleteOption<InCatalogResponse | CachedProductsAndCategories>[];
      selectedItems.map((option: AutocompleteOption<InCatalogResponse | CachedProductsAndCategories>) => {
        if (option?.catalogType && option.catalogType === 'CATEGORY') {
          itemsCount.categories += 1;
        } else if (option?.catalogType && option?.catalogType === 'PRODUCT') {
          itemsCount.products += 1;
        }
      });

      this.selectedItemsCount = { ...itemsCount };
    });
    if (this.allSelectedCondition) {
      this.onAllSelectedChange(true);
    }
    this.additionalOptions$.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      this.#initailOptions = data;
    });
  }
  private updateMenuPosition() {
    setTimeout(() => {
      if (this.dropdownMenu?.overlayRef?.hasAttached() && this.dropdownMenu?.overlayRef?.overlayElement?.style) {
        this.dropdownMenu.overlayRef.updatePosition();
      }
    });
  }
  private initSelected() {
    this.selectedItems$ = combineLatest([this.optionsList$, this.selectedIds$]).pipe(
      takeUntil(this.destroyed$),
      map(([options, selectedIds]) => {
        const selectedOptions: (AutocompleteOption | null)[] = [...(selectedIds || [])].map((id) => {
          const fromShownNodes = options.find((option) => id === option.id);

          if (fromShownNodes) {
            return fromShownNodes as AutocompleteOption;
          }

          let additionalOption = this.#initailOptions.find((option) => option.id === id);

          if (this.getSelectedOptionFn && !additionalOption) {
            return this.getSelectedOptionFn(id);
          }

          if (additionalOption) {
            return additionalOption;
          }

          return null;
        });

        return selectedOptions.filter(notEmpty);
      }),
      tap(() => {
        this.updateMenuPosition();
      }),
    );

    this.selectedValue$ = this.selectedItems$.pipe(
      takeUntil(this.destroyed$),
      map((items) => {
        const [selected] = items;
        return selected;
      }),
    );

    combineLatest([
      this.selectedIds$.pipe(pairwise()),
      this.selectedItems$.pipe(
        tap((selectedItems) => {
          const [selected] = selectedItems;
          if (!this.isMulti && this.showSearchInput && selected) {
            this.searchText = this.displayValue(selected);
          }
        }),
        skip(1),
      ),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([[previousValue], selectedItems]) => {
        if (!!previousValue && (!this.isCustomInput || selectedItems.length > 0)) {
          this.onChange(this.getValueToEmit(selectedItems as AutocompleteOption[]));
        }

        const [selected] = selectedItems;

        if (!this.isMulti) {
          this.valueColor = selected?.imageColor;
          this.valueIcon = selected?.iconLeft;
          this.valueImage = selected?.imageUrl;
        }

        this.isDenseMode = this.isMulti ? !!selectedItems.length : this.selectedHasPrefix;

        this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  private getValueToEmit(items: Array<AutocompleteOption>): string | string[] | null | AutocompleteOption | AutocompleteOption[] {
    const [singleItemValue] = items;

    if (this.returnObjects) {
      return this.isMulti ? items : singleItemValue;
    }

    if (this.isMulti) {
      return items.map((item) => item.id);
    }

    if (this.isCustomInput) {
      return this.displayValue(singleItemValue);
    }

    return singleItemValue?.id;
  }

  private initMenuWidth() {
    const inputWidth = this.fieldElem?.nativeElement?.offsetWidth;
    const overlayPanel = this.getMenuElem();
    if (inputWidth && overlayPanel) {
      overlayPanel.style.width = `${inputWidth}px`;
    }
  }

  private singleValueIsSelected(): boolean {
    return !this.isMulti && !!this.selectedIdsValue.size;
  }

  private getMenuElem(): HTMLElement {
    return document.getElementsByClassName('nm-autocomplete-menu').item(0) as HTMLElement;
  }

  canSearch(): boolean {
    return (this.search && this.searchText.length >= this.searchCharsCount) || this.searchText.length === 0;
  }

  getCreateLabel(): string {
    let label = '+ Создать';
    if (this.searchText) {
      label += `: ${this.searchText}`;
    }
    return label;
  }

  removeItem(option: AutocompleteOption) {
    const selected = [...this.selectedIdsValue].filter((id) => option.id !== id);
    this.updateSelectedIds(new Set(selected));
    this.selectedItems$ = combineLatest([this.optionsList$, this.selectedIds$]).pipe(
      takeUntil(this.destroyed$),
      map(([options, selectedIds]) => {
        const selectedOptions: (AutocompleteOption | null)[] = [...(selectedIds || [])].map((id) => {
          const fromShownNodes = options.find((option) => id === option.id);

          if (fromShownNodes) {
            return fromShownNodes as AutocompleteOption;
          }

          if (this.getSelectedOptionFn) {
            return this.getSelectedOptionFn(id);
          }

          return null;
        });
        return selectedOptions.filter(notEmpty);
      }),
      tap(() => {
        this.updateMenuPosition();
      }),
    );

    this.deleteChip.emit(selected);
  }

  onSearchChange(text: string) {
    this.addOptions([]);
    if (this.canSearch()) {
      this.searchText$.next(text);
      this.searchText = text;
    }
    if (!this.isMulti) {
      this.updateSelectedIds(new Set());
      if (this.isCustomInput) {
        this.onChange(text);
      } else {
        this.onChange(null);
      }
    }
  }

  displayValue(option: AutocompleteOption | null): string {
    this.cdr.markForCheck();
    return (option && option.label) || '';
  }

  clear(event: Event) {
    event.stopPropagation();
    if (this.disabled) return;

    this.searchText = '';
    this.updateSelectedIds(new Set());
    this.onSearchChange('');
    this.deselectAll.emit();
    this.selectedItemsCount = { categories: 0, products: 0 };
    this.allSelectedCondition = false;

    if (this.isMulti) {
      this.onChange([]);
    } else {
      this.onChange(null);
    }
  }

  onMenuOpened() {
    if (this.inputElem) {
      this.inputElem.nativeElement.focus();
    }
    this.isFocused = true;
    this.isMenuOpened = true;
    this.menuOpen.emit();
  }

  onMenuClosed() {
    if (this.canSelectAll() && !!this.searchText) {
      this.searchText = '';
      this.searchText$.next('');
    }
  }

  canSelectAll(): boolean {
    return !!this.allSelected;
  }

  updateSelectedIdsFromMenu(itemsIds: Set<string>): void {
    if (this.disableModelUpdatesFromMenu) {
      return;
    }
    this.updateSelectedIds(itemsIds);
  }

  updateSelectedIds(itemsIds: Set<string>): void {
    this.#selectedIds.next(itemsIds);
  }

  addOptions(options: AutocompleteOption[]) {
    let currentAdditionalOptions = this.#additionalOptions.getValue();
    let additionalOptions = options.concat(currentAdditionalOptions);

    this.#additionalOptions.next(additionalOptions);
  }

  loadOptions() {
    this.loadMore.emit();
  }

  private writeMultiValue(value: AutocompleteOption[] | null): void {
    if (!value || !value.length) {
      this.deselectAll.emit();
    }
    if (value?.length) {
      this.addOptions(value);
    }
    if (value && value?.length) {
      this.updateSelectedIds(new Set(value?.map((item) => item.id)));
    } else {
      this.updateSelectedIds(new Set([]));
    }
  }

  private writeSingleValue(value: AutocompleteOption | string | null): void {
    let val: string | undefined;
    const isStringValue = typeof value === 'string';
    if (this.isCustomInput && isStringValue) {
      this.searchText = value;
      this.searchText$.next(value);
      return;
    } else {
      val = isStringValue ? value : value?.id;
    }

    if (value && !isStringValue) {
      this.addOptions([value]);
    }

    this.updateSelectedIds(val ? new Set([val]) : new Set([]));
  }

  writeValue(value: AutocompleteOption | null | AutocompleteOption[] | string): void {
    const isArray = Array.isArray(value);
    if (this.isMulti) {
      this.writeMultiValue(isArray ? value : []);
    } else if (!isArray) {
      this.searchText = '';
      this.writeSingleValue(value);
    }
  }

  registerOnChange(fn: FunctionType): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: FunctionType): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  focusInput() {
    if (this.inputElem) {
      this.inputElem.nativeElement.focus();
    }
  }

  closeMenu() {
    this.isMenuOpened = false;
    this.dropdownMenu.close();
  }

  onAllSelectedChange(event: boolean) {
    this.allSelectedCondition = event;
    this.selectAllHandler.emit({ selected: event, updateSelectedItems: true });
    if (this.autocompleteTreeService) {
      this.autocompleteTreeService.selectAllHandler({ selected: event, updateSelectedItems: true });
    }
    this.updateMenuPosition();

    if (!event) {
      this.updateSelectedIds(new Set<string>());
      this.selectedItemsCount = {
        categories: 0,
        products: 0,
      };
    }
  }

  onItemCheckboxChange(event: MatCheckboxChange) {
    if (!event.checked) {
      this.allSelectedCondition = false;
      this.selectAllHandler.emit({ selected: false, updateSelectedItems: false });
      this.updateMenuPosition();
    }
  }

  getProductsAndCategories() {
    return this.selectedItemsCount.categories + ' категории' + ' • ' + this.selectedItemsCount.products + ' товаров';
  }
}
