import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatMenu, MenuPositionX, MenuPositionY } from '@angular/material/menu';
import { BehaviorSubject } from 'rxjs';

import { MenuItem } from '@app/shared/component/menu-item/menu-item.model';
import { ButtonVariant } from '@app/utils/constants/button-variants';

import { SelectAllSettings } from '../../autocomplete/autocomplete.model';
import { FilterTemplate } from '../../filters-table/filters-table.component';

@Component({
  selector: 'nm-menu-content',
  templateUrl: './menu-content.component.html',
  styleUrls: ['./menu-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuContentComponent {
  @Input() spinner: TemplateRef<unknown>;
  @Input() items: MenuItem[] = [];
  @Input() selectedItems: BehaviorSubject<Set<string>> | Set<string> = new Set<string>();
  @Input() showDescription: boolean = true;
  @Input() allowMultiselect: boolean = false;
  @Input() maxSelectedItems = 0;
  @Input() showRadioSelect: boolean = false;
  @Input() isIndeterminate = false;
  @Input() checkboxPosition: 'left' | 'right' | 'none' = 'none';
  @Input() selectOnItemClick: boolean = true;
  @Input() closeAfterItemClick: boolean = true;
  @Input() header: MenuItem | null = null;
  @Input() xPosition: MenuPositionX = 'after';
  @Input() yPosition: MenuPositionY = 'below';
  @Input() showCancelButton: boolean = false;
  @Input() cancelButtonLabel: string = 'Отменить';
  @Input() cancelButtonVariant: ButtonVariant = 'outlined';
  @Input() showOkButton: boolean = false;
  @Input() okButtonLabel: string = 'Добавить';
  @Input() okButtonVariant: ButtonVariant = 'filled';
  @Input() showSearch: boolean = false;
  @Input() hasBackdrop: boolean = true;
  @Input() searchText: string = '';
  @Input() maxHeight: number | null = null;
  @Input() maxItemsHeight: number | null = null;
  @Input() minWidth: number | null = 140;
  @Input() maxWidth: number | null = null;
  @Input() itemsLoading: boolean = false;
  @Input() panelClass: string = '';
  @Input() isCloseAfterCancelClick: boolean = false;
  @Input() isCloseAfterOkClick: boolean = false;
  @Input() filtersTemplates: FilterTemplate[] = [];
  @Input() virtualScrolling: boolean = false;
  @Input() virtualScrollStartsFrom: number = 100;
  @Input() canLoadMore: boolean = false;
  @Input() selectAllSettings: SelectAllSettings;
  @Input() allSelected: boolean;
  @Input() multiSelect: boolean = false;
  @Input() searching: boolean = false;
  @Input() testId: string;
  @Input() isStoreFilter: boolean = false;
  @Input() okClickDisabled = false;

  @Output() selectedItemsChange = new EventEmitter<Set<string>>();
  @Output() cancelClick = new EventEmitter<void>();
  @Output() okClick = new EventEmitter<Set<string> | BehaviorSubject<Set<string>>>();
  @Output() itemClick = new EventEmitter<MenuItem>();
  @Output() checkboxChange = new EventEmitter<MatCheckboxChange>();
  @Output() searchTextChange = new EventEmitter<string>();
  @Output() closed = new EventEmitter<void>();
  @Output() loadMoreItems = new EventEmitter<void>();
  @Output() allSelectedChange = new EventEmitter<boolean>();

  @ViewChild('childMenu', { static: true }) childMenu: MatMenu;
  @ViewChild('itemsContainer', { static: false }) itemsContainer: ElementRef;
  isOpen = false;
  get selected(): Set<string> {
    if (this.selectedItems instanceof BehaviorSubject) {
      return this.selectedItems.getValue();
    } else {
      return this.selectedItems;
    }
  }

  get maxHeightValue(): string {
    return this.maxHeight ? `${this.maxHeight}px` : 'none';
  }

  get maxItemsHeightValue(): string {
    return this.maxItemsHeight ? `${this.maxItemsHeight}px` : 'none';
  }

  get minWidthValue(): string {
    return this.minWidth ? `${this.minWidth}px` : 'none';
  }

  get maxWidthValue(): string {
    return this.maxWidth ? `${this.maxWidth}px` : 'none';
  }

  get displayVitualScroll(): boolean {
    return this.virtualScrolling && this.items.length > this.virtualScrollStartsFrom;
  }

  onCheckboxChange(item: MenuItem, event: MatCheckboxChange) {
    this.checkboxChange.emit(event);

    if (item.onCheckboxChange) {
      item.onCheckboxChange(event.checked);
    }
  }

  onSelectedChange(item: MenuItem) {
    if (item.isIndeterminate) {
      return;
    }

    const id = item.id;

    if (!id) {
      throw new Error(`Id is missing in ${item} MenuItem`);
    }

    let newSelected: Set<string>;

    if (this.allowMultiselect) {
      if (this.maxSelectedItems && this.maxSelectedItems === this.selected.size) {
        newSelected = new Set(this.selected);
        newSelected.add(id);
        const ids = [...newSelected];
        ids.shift();
        newSelected = new Set(ids);
      } else {
        newSelected = new Set(this.selected);
        newSelected.has(id) ? newSelected.delete(id) : newSelected.add(id);
      }
    } else {
      newSelected = new Set([id]);
    }

    if (this.selectedItems instanceof BehaviorSubject) {
      this.selectedItems.next(newSelected);
    } else {
      this.selectedItems = newSelected;
      this.selectedItemsChange.emit(newSelected);
    }
  }

  close() {
    this.isOpen = false;
  }

  handleCancelClick(e: MouseEvent) {
    if (!this.isCloseAfterCancelClick) {
      e.stopPropagation();
    }

    this.cancelClick.emit();
  }

  handleOkClick(e: MouseEvent) {
    if (!this.isCloseAfterOkClick) {
      e.stopPropagation();
    }
    this.okClick.emit(this.selectedItems);
  }

  handleItemClick(item: MenuItem, e: MouseEvent) {
    this.itemClick.emit(item);
    if (item.onClick) {
      const id = item.id;
      const checked = this.selected.has(id || '');

      item.onClick(checked);
    }

    if (!this.closeAfterItemClick) {
      e.stopPropagation();
    }
  }

  onClosed(): void {
    this.closed.emit();
  }

  onCheckboxClick(item: MenuItem, isSelected: boolean): void {
    if (item.onCheckboxClick) {
      item.onCheckboxClick(isSelected);
    }
  }

  onRightIconClick(item: MenuItem): void {
    if (item.onRightIconClick) {
      item.onRightIconClick();
    }
  }

  onExpandClick(item: MenuItem, isExpanded: boolean): void {
    if (item.onExpandClick) {
      item.onExpandClick(isExpanded);
    }
  }

  onItemImageLoad(item: MenuItem): void {
    if (item.onImageLoad) {
      item.onImageLoad();
    }
  }

  onItemImageError(item: MenuItem): void {
    if (item.onImageLoadError) {
      item.onImageLoadError();
    }
  }
  onAllSelectedChange(event: boolean): void {
    this.allSelectedChange.emit(event);
  }
}
