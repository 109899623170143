import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';

import { SharedPipesModule } from '@app/shared/pipe/pipes.module';
import { InputMaskModule } from '@ngneat/input-mask';
import { LetModule } from '@ngrx/component';

import { AvatarModule } from '../avatar/avatar.module';
import { ChipModule } from '../chip/chip.module';
import { ControlHintModule } from '../control-hint/control-hint.module';
import { ControlLabelModule } from '../control-label/control-label.module';
import { DropdownMenuModule } from '../dropdown-menu/dropdown-menu.module';
import { FormFieldModule } from '../form-field/form-field.module';
import { MenuModule } from '../menu/menu.module';
import { MenuItemModule } from '../menu-item/menu-item.module';
import { ProgressSpinnerModule } from '../progress-spinner/progress-spinner.module';
import { SvgIconModule } from '../svg-icon/svg-icon.module';

import { AutocompleteComponent } from './autocomplete.component';

@NgModule({
  declarations: [AutocompleteComponent],
  imports: [
    CommonModule,
    ControlLabelModule,
    FormsModule,
    SvgIconModule,
    ControlHintModule,
    ReactiveFormsModule,
    FormFieldModule,
    MenuModule,
    MatMenuModule,
    MenuItemModule,
    ChipModule,
    SharedPipesModule,
    AvatarModule,
    ProgressSpinnerModule,
    DropdownMenuModule,
    LetModule,
    InputMaskModule,
    MatInputModule,
  ],
  exports: [AutocompleteComponent],
})
export class AutocompleteModule {}
